import React from "react";
import './youtubeEmbed.css'
import './about.css'
import { YoutubeEmbed } from './youtubeEmbed';


export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/aboutScreenshot.svg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>

              
              
              
            </div>
            
          </div>
        </div>
        <div className="one-hundred-percent-green">
          <h3>"100% Green" Is Not Currently Possible...</h3>
          <p>{props.data ? props.data.GoogleVideo : "loading..."}</p>
          <div className="video-container">
            <YoutubeEmbed start={10} end={28} embedId={'bv3PkghWPys'} title={'Why The Grid Is Not 100% Green'}/>
          </div>
        </div>
      </div>
    </div>
  );
};
