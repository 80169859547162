

export const BlogArea = () => {
  return (
    <div id='blog-area' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Blog</h2>
        </div>
        <img src="../../img/blogIconGreenNew.svg" alt="" className='blog-icon'/>
        <p>Welcome to our blog where we give you an honest lowdown on the Texas Energy Market. Our goal is to provide a fair and honest assessment of the market place and explain how things really work. Expect insights and stories you wont find elsewhere and that other retailers might not want you to know about.</p>
        <a
            href='/blog'
            className='btn btn-custom btn-lg page-scroll'
        >
            Blog Home
        </a>{' '}
      </div>
    </div>
  )
}
