import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Devices} from "./components/devices";
import { Gallery } from "./components/gallery";
import { Register } from "./components/register";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import { PageNotFound } from "./components/pageNotFound";
import JsonData from "./data/data.json";
import BlogData from "./data/blogData.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Popup } from "./components/popup";
import { LoadingSpinner } from "./components/loadingSpinner";
import {BlogDetails } from "./components/blogDetails";
import { BlogStory } from "./components/blogStory";  
import { BlogArea } from "./components/blogArea";
import { BlogAbout } from "./components/blogAbout";
import * as serviceWorker from './serviceWorker';

import { BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

serviceWorker.unregister();

const HomePage = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState('This is a test');
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation headerType='main'/>
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Devices data={landingPageData.Devices} />
      {/* <Register data={landingPageData.Register} popupMessage = {setPopupText} popupState={setShowPopup}/> */}
      <Register data={landingPageData.Register} setShowPopup={setShowPopup} setPopupText={setPopupText} setShowLoadingSpinner={setShowLoadingSpinner}/>
      <BlogArea/>
      <Contact data={landingPageData.Contact} setShowPopup={setShowPopup} setPopupText={setPopupText} setShowLoadingSpinner={setShowLoadingSpinner}/>
      <Popup trigger={showPopup} buttonName = {'Close'} paragraph = {popupText} setShowPopup={setShowPopup} /> 
      <LoadingSpinner trigger={showLoadingSpinner} />
    </div>
  )
}

const BlogPage = () => {
  const [blogData, setBlogData] = useState(BlogData)
  useEffect(() => {
    setBlogData(BlogData);
  }, []);
  return (
    <div>
      <Navigation headerType='blog'/>
      <BlogDetails data={blogData}/>
    </div>
  )
}

const PageNotFoundPage = () => {
  return (
    <div>
      <Navigation headerType='page-not-found'/>
      <PageNotFound />
    </div>
  )
}


const BlogStoryPage = () => {
  const [blogData, setBlogData] = useState(BlogData)
  useEffect(() => {
    setBlogData(BlogData);
  }, []);
  const { blogurl } = useParams();

  const resultUrl = checkUrl(blogurl);

  // check valid
  function checkUrl(url) {
    var result;
    var pageData = blogData.find(obj => obj.url == url);
    if (pageData == undefined) {
      result = 'error';
    } else {
      result = 'okay';
    }
    return result;

  }

  if (resultUrl == 'okay') {
    return (
      <div>
        <Navigation headerType='blog'/>
        <BlogStory url={blogurl} data={blogData} />
        <BlogAbout/>
      </div>
    )
  } else {
    return <PageNotFoundPage />
  }
  
  
}




const App = () => {
  

  // function showPopupFunc(showBoolean) {
  //   setShowPopup(showBoolean)
  // }

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/test" element={<HomePage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:blogurl" element={<BlogStoryPage />} />
          <Route path="/blog/*" element={<PageNotFoundPage />} />
          <Route path="/navigation" element={<Navigation />} />
          <Route path="/*" element={<PageNotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
