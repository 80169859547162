import React from 'react'
import './loadingSpinner.css'
import { BeatLoader } from 'react-spinners';

export const LoadingSpinner = (props) => {


  return (props.trigger) ? (
    <div className='spinner'>
        
        <div className='spinner-inner'>
            <BeatLoader
                color="#289B41"
            />
        </div>
    </div>
  ) : "";
}

