
import { BlogCard } from './card'
import Grid from '@mui/material/Grid';

export const BlogDetails = (props) => {
  return (
    <div className='blog-details-main'>
        <h1>TruClean Power Blog</h1>
        <img src="../../img/blogIconGreenNew.svg" alt="" className='blog-icon'/>
        <p>Welcome to our blog where we give you the latest information on the Texas Energy Market. Our goal is to provide a fair and honest assessment of the market place and explain how things really work. Expect insights and stories you wont find elsewhere and that other retailers might not want you to know about.</p>
        <div className='card-container'>
          {props.data
            ? props.data.map((d) => (
              <BlogCard title={d.title} date={d.date} description={d.description} imgFile={d.imgFile} description={d.description} url={d.url}/>
            ))
            : "loading..."
          }
        </div>
        <div className='container text-center'>
          <p>
            &copy; 2022 TruClean Power
          </p>
        </div>
    </div>
    

    
  )
}

// export const BlogDetails = (props) => {
//   return (
//     <div className='blog-details-main'>
//         <h1>TruClean Power Blog</h1>
//         <img src="../../img/blogIconGreenNew.svg" alt="" className='blog-icon'/>
//         <p>Welcome to our blog where we give you an honest lowdown on the Texas Energy Market. Our goal is to provide a fair and honest assessment of the market place and explain how things really work. Expect insights and stories you wont find elsewhere and that other retailers might not want you to know about.</p>
//         <div className='card-container'>
//           {props.data
//             ? `found data... ${props.data}` 
//             : "loading..."
//           }
//         </div>
//     </div>

    
//   )
// }
