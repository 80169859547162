import React from "react";


export const YoutubeEmbed = (props) => {

    // check if start and end time are provided
    let youtubeUrl;

    if (props.start === undefined && props.end === undefined) {
        youtubeUrl = `https://www.youtube.com/embed/${props.embedId}`
    }
    else if (props.start === undefined && props.end !== undefined) {
        youtubeUrl = `https://www.youtube.com/embed/${props.embedId}?start=${props.start}`
    }
    else if (props.start ==! undefined && props.end === undefined) {
        youtubeUrl = `https://www.youtube.com/embed/${props.embedId}?start=${props.end}`
    }
    else {
        youtubeUrl = `https://www.youtube.com/embed/${props.embedId}?start=${props.start}&end=${props.end}`
    }
    
  return (

  <div className="video-responsive">
    <iframe
      width="100%"
      height="100%"
      src={youtubeUrl}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={props.title}
    />
  </div>
)}
;


