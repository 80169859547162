import { Image } from "./image";
import { useState } from "react";
// import axios from "axios";
// import fetch from 'isomorphic-fetch';
// import promise from 'es6-promise';
import 'whatwg-fetch';
import axios from "axios";

// promise.polyfill()



const API_URL = 'https://qeo55d5tpa.execute-api.us-west-2.amazonaws.com/prod/registerusernew?email=';
// const API_URL_POST = 'https://qeo55d5tpa.execute-api.us-west-2.amazonaws.com/prod/registeruser';
const API_URL_POST = 'https://api.trucleanpower.com/registeruser';

export const Register = (props) => {

  const [email, setEmail] = useState("");

  const handleEmailChange = event => {
    setEmail(event.target.value)
  };

  const handleSubmit = event => {
    event.preventDefault();
    alert(`Your state values: \n 
            email: ${email} \n 
            You can replace this alert with your process`);
  };

  // const setPopover = props.popupState;
  // const setPopupText = props.popupMessage;

  async function saveEmail() {
      const response = await window.fetch(`${API_URL}${email}`);


      const data = await response.json();

      let messageResponse;

      const statusCode = response.status;
      console.log(`status code is ${statusCode}`);

      if (statusCode == '200') {
          messageResponse = data.message;
      } else {
          messageResponse = data.error;
      }
      alert(`${messageResponse}`);
      // setPopupText(messageResponse);
      // setPopover(true);

      setEmail("");
  }

  function saveEmailNew() {
      let messageResponse;
      axios
        .get(`${API_URL}${email}`)
        .then(response => {
          messageResponse = response.data;
          console.log(messageResponse);

          
        })
        .catch(error => {
          console.log(`ERROR: ${error.response.data}`);
          messageResponse = error.response.data.error;
          console.log(messageResponse)
      });

      alert(`${messageResponse}`);
      // setPopupText(messageResponse);
      // setPopover(true);

      setEmail("");
    
  }

  async function saveEmailAxiosAwait() {
    try{
      let responseData = await axios.get(`${API_URL}${email}`);
      console.log(responseData);
    }catch(error){
        console.log(Object.keys(error), error.message);
    }
    alert('Function finished');

  }

  const saveEmailPost = async (e) => {
    e.preventDefault()

    props.setShowLoadingSpinner(true);


    const contactObject = {

      email: email

    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(contactObject),

    }

    let response;

    try {
      response = await axios.post(API_URL_POST, contactObject);
      console.log(response.data);
    } catch (err) {
        // Handle Error Here
        console.error(err);
    }


    const data = await response.data.body

    props.setShowLoadingSpinner(false);

    let messageResponse;

    const statusCode = response.data.statusCode
    console.log(`status code is ${statusCode}`)


    messageResponse = data.message;
    if (messageResponse == null) {
        messageResponse = data.error;
    }
    setEmail("");
    // alert(`${messageResponse}`);
    props.setPopupText(messageResponse);
    props.setShowPopup(true);

  }
  



  return (
    <div id="register" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Register</h2>
          <p>{props.data ? props.data.paragraph : "loading..."}</p>
        </div>
        {/* register form */}
        <form onSubmit={saveEmailPost}>
          <div className="mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="Enter email"
              onChange={handleEmailChange}
              value={email}
            />
          </div>

          <div className="d-grid">
            <button type="submit" className="btn btn-custom btn-lg">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
