import React from 'react'
import './popup.css'


export const Popup = (props) => {


  return (props.trigger) ? (
    <div className='popup'>
        
        <div className='popup-inner'>
            <p><b>{props.paragraph}</b></p>
            <button className='btn btn-custom btn-lg' onClick={() => {props.setShowPopup(false)}}>{props.buttonName}</button>
            {props.children}
        </div>
    </div>
  ) : "";
}

