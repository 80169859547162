import React, {useState, useEffect} from "react";
import parse from 'html-react-parser';
// import './blogStory.css'

export const BlogStory = (props) => {

  const displayData = getPageData(props.url);

  function getPageData(url) {
    var pageData = props.data.find(obj => obj.url == url);
    return pageData;
  }

  return (
    <div className='blog-story-new-wrapper'>
      <div className="blog-story-main">
        <h1>{displayData.title}</h1>
        <h3>{displayData.date} | {displayData.readtime}</h3>
        <img src={`../../img/blog/${displayData.imgFile}`} alt="" className='blog-story-main-img'/>
        <div className="blog-story-text-container">
          {parse(displayData.blogHtml)}
        </div>
      </div>
    </div>

    
  )
}