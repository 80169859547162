import './pageNotFound.css'


export const PageNotFound = () => {
  return (
    <div className="page-not-found-main">
        <img src="../../img/pageNotFound.svg" alt="Page Not Found" />
        <h1>Page Not Found</h1>
        <p>Something unexpected has happened. Please return to our home page. We apologize for any inconvenience.</p>
        <a href="/">
            <img src="../../img/icon.svg" alt="" />
            <h3>Return Home</h3>
        </a>
    </div>
  )
}
