export const Devices = (props) => {
    return (
      <div id='devices' className='text-center'>
        <div className='container'>
          <div className='section-title'>
            <h2>Smart Devices</h2>
            <p>
              Our product integrates with all your favorite smart devices and our optimization algorithms control your devices to maximize green energy usage and save you money!
            </p>
          </div>
          <div className='row'>
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className='col-md-4'>
                    {' '}
                    {/* <i className={d.icon}></i> */}
                    <img src={`/img/devices/${d.image}`} alt="thermostat" className="device-icon"/>
                    <div className='service-desc'>
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                ))
              : 'loading'}
          </div>
        </div>
      </div>
    )
  }