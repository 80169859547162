import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';




export const BlogCard = (props) => {

    const imgUrl = `../../img/blog/${props.imgFile}`
    const blogPageUrl = `/blog/${props.url}`
    return (
        <Card sx={{ maxWidth: 500 }} className="blog-card">
          <CardActionArea href={blogPageUrl}>
            <CardMedia
              component="img"
              height="250"
              image={imgUrl}
              alt={props.imgDescription}
            />
            <CardContent>
              <Typography gutterBottom variant="h4" component="div">
                {props.title}
              </Typography>
              <Typography gutterBottom variant="h7" component="div">
                {props.date}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {props.description}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      );
}
