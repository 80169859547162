export const BlogAbout = () => {
    return (
      <div id='blog-about-area' className='text-center'>
        <div className='container'>
          <div>
            <h2>About Us</h2>
          </div>
          <p>We are a new cleantech startup with a revolutionary product that offers the only way to buy truly green energy. Being green is about matching your energy usage to when green energy is available on the grid. We are the only company to give you this information, inform you of grid conditions, help you adjust your usage to cleaner times, and charge you less for using more <a href="/"><b>TruClean Power</b></a>! Check out our website to learn more!</p>
          <a className='blog-about-area-icon-text' href='/'>
              truclean power
            {' '}
            <img src="../../img/icon.svg" alt="icon" className="icon-navigation"/>
            </a>
        </div>
        <div className='container text-center'>
          <p>
            &copy; 2022 TruClean Power
          </p>
        </div>
      </div>
    )
  }