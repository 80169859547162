import { useState } from 'react';
import axios from 'axios';








const initialState = {
  name: '',
  email: '',
  message: '',
}

// const API_URL = 'https://qeo55d5tpa.execute-api.us-west-2.amazonaws.com/prod/websitesendmessage';
const API_URL = 'https://api.trucleanpower.com/websitesendmessage';

export const Contact = (props) => {
  // const [{ name, email, message }, setState] = useState(initialState)
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const[message, setMessage] = useState('');



  const handleSubmit = async (e) => {
    e.preventDefault();
    props.setShowLoadingSpinner(true);
    console.log(name, email, message);
    console.log(e.target.name.value);

    const contactObject = {
      name: name,
      email: email,
      message: message
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(contactObject),

    }

    let response;

    try {
      response = await axios.post(API_URL, contactObject);
      console.log(response.data);
    } catch (err) {
        // Handle Error Here
        console.error(err);
    }


    const data = await response.data.body

    let messageResponse;

    const statusCode = response.data.statusCode
    console.log(`status code is ${statusCode}`)


    messageResponse = data.message;
    if (messageResponse == null) {
        messageResponse = data.error;
    }
    props.setShowLoadingSpinner(false);

    setName('');
    setEmail('');
    setMessage('');
    e.target.reset();
    // alert(`${messageResponse}`);
    props.setPopupText(messageResponse);
    props.setShowPopup(true);
    
    
    
  }
  return (
    
    <div>
      {/* <Popup trigger={true} buttonName = {'test'} paragraph = {'This is a test'}/> */}
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>
                  Have further questions? Please fill out the form below and our team will get back to you as soon as possible.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={event=> setName(event.target.value)}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={event=> setEmail(event.target.value)}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={event=> setMessage(event.target.value)}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                <a href={props.data ? props.data.facebook : 'loading'}><i className='fa fa-facebook-square'></i> Facebook</a>
                </span>{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2022 TruClean Power
          </p>
        </div>
      </div>
    </div>
  )
}
