export const Navigation = (props) => {

  if (props.headerType === 'main') {
    // homepage header
    return (
      <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
        <div className='container'>
          <div className='navbar-header'>
            <button
              type='button'
              className='navbar-toggle collapsed'
              data-toggle='collapse'
              data-target='#bs-example-navbar-collapse-1'
            >
              {' '}
              <span className='sr-only'>Toggle navigation</span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
            </button>
            <a className='navbar-brand page-scroll' href='#page-top'>
              truclean power
            </a>{' '}
            <img src="../../img/icon.svg" alt="icon" className="icon-navigation"/>
          </div>


          <div
            className='collapse navbar-collapse'
            id='bs-example-navbar-collapse-1'
          >
            <ul className='nav navbar-nav navbar-right'>
              {/* main page */}
              
              <li>
                <a href='#features' className='page-scroll'>
                  Features
                </a>
              </li>
              <li>
                <a href='#about' className='page-scroll'>
                  About
                </a>
              </li>
              <li>
                <a href='#devices' className='page-scroll'>
                  Devices
                </a>
              </li>
              <li>
                <a href='#register' className='page-scroll'>
                  Register
                </a>
              </li>
              <li>
                <a href='/blog' className='page-scroll'>
                  Blog
                </a>
              </li>
              <li>
                <a href='#contact' className='page-scroll'>
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  } else if (props.headerType === 'blog') {
    // return blog header
    return (
      <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
        <div className='container'>
          <div className='navbar-header'>
            <button
              type='button'
              className='navbar-toggle collapsed'
              data-toggle='collapse'
              data-target='#bs-example-navbar-collapse-1'
            >
              {' '}
              <span className='sr-only'>Toggle navigation</span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
            </button>
            <a className='navbar-brand page-scroll' href='/'>
              truclean power
            </a>{' '}
            <img src="../../img/icon.svg" alt="icon" className="icon-navigation"/>
          </div>


          <div
            className='collapse navbar-collapse'
            id='bs-example-navbar-collapse-1'
          >
            <ul className='nav navbar-nav navbar-right'>
              {/* main page */}
              
              <li>
                <a href='/' className='page-scroll'>
                  Home
                </a>
              </li>
              <li>
                <a href='/blog' className='page-scroll'>
                  Blog
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  } else if (props.headerType === 'pageNotFound') {
    // return blog page
    return (
      <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
        <div className='container'>
          <div className='navbar-header'>
            <button
              type='button'
              className='navbar-toggle collapsed'
              data-toggle='collapse'
              data-target='#bs-example-navbar-collapse-1'
            >
              {' '}
              <span className='sr-only'>Toggle navigation</span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
            </button>
            <a className='navbar-brand page-scroll' href='/'>
              truclean power
            </a>{' '}
            <img src="../../img/icon.svg" alt="icon" className="icon-navigation"/>
          </div>


          <div
            className='collapse navbar-collapse'
            id='bs-example-navbar-collapse-1'
          >
            <ul className='nav navbar-nav navbar-right'>
              {/* main page */}
              
              <li>
                <a href='/' className='page-scroll'>
                  Home
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  } else {
    // return blog header
    return (
      <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
        <div className='container'>
          <div className='navbar-header'>
            <button
              type='button'
              className='navbar-toggle collapsed'
              data-toggle='collapse'
              data-target='#bs-example-navbar-collapse-1'
            >
              {' '}
              <span className='sr-only'>Toggle navigation</span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
            </button>
            <a className='navbar-brand page-scroll' href='/'>
              truclean power
            </a>{' '}
            <img src="../../img/icon.svg" alt="icon" className="icon-navigation"/>
          </div>


          <div
            className='collapse navbar-collapse'
            id='bs-example-navbar-collapse-1'
          >
            <ul className='nav navbar-nav navbar-right'>
              {/* main page */}
              
              <li>
                <a href='/' className='page-scroll'>
                  Home
                </a>
              </li>
              <li>
                <a href='/blog' className='page-scroll'>
                  Blog
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}
